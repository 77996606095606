exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendly-js": () => import("./../../../src/pages/calendly.js" /* webpackChunkName: "component---src-pages-calendly-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-website-design-and-dev-terms-js": () => import("./../../../src/pages/website-design-and-dev-terms.js" /* webpackChunkName: "component---src-pages-website-design-and-dev-terms-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-discovery-js": () => import("./../../../src/templates/discovery.js" /* webpackChunkName: "component---src-templates-discovery-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-mobile-apps-js": () => import("./../../../src/templates/mobile-apps.js" /* webpackChunkName: "component---src-templates-mobile-apps-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-services-branding-js": () => import("./../../../src/templates/services-branding.js" /* webpackChunkName: "component---src-templates-services-branding-js" */),
  "component---src-templates-services-seo-js": () => import("./../../../src/templates/services-seo.js" /* webpackChunkName: "component---src-templates-services-seo-js" */),
  "component---src-templates-services-website-js": () => import("./../../../src/templates/services-website.js" /* webpackChunkName: "component---src-templates-services-website-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

